import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { ExistsIn } from "src/app/_helpers/ArrayExtensionMethodHelper.functions";
import { ActorActionEnum } from "src/app/constants/actor-action.enum";
import { StatusEnum } from "src/app/constants/status.enum";
import { StringHelper } from "src/app/directives/string-helper";
import { DocumentActor } from "src/app/models/actors";
import { DocumentInfoForToast, DocumentModel, DocumentIsLockedModel } from "src/app/models/document.model";
import { RequestModel } from "src/app/models/requests";
import { User } from "src/app/models/user";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DossierService } from "src/app/services/dossier.service";
import { OrganisationSettingsApiClient } from "src/app/services/organisation-settings.apiclient";
import { PersonApiClient } from "src/app/services/api-clients/person.api-client";
import { RequestApiClient } from "src/app/services/request.apiclient";
import { ToastService } from "src/app/services/toast.service";
import { ViewerHubService } from "src/app/services/viewerhub.service";

/**
 * THE service for the viewer that holds the information
 * So all Components of the Viewer can Access (Page) information without having to resort to Inputs/Outputs
 */
@Injectable()
export class ViewerDataService implements OnDestroy {


    /**
     * Listener for the wiggler!!!
     * True = WIGGLE BABY
     * False = (> *.*)> Y u no wiggle!
     */
    public letUsWiggle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    /**
     * Listener for aiding in navigating towards the next action
     * True = after completion of an action, goto the next
     * False = retain current position in page
     */
    public navigateThroughActions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Notifies when the Viewer's data is ready to be used
     */
    public initializationDone$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /**
     * Notifies when the Viewer failed to get data
     */
    public initializationFailed$: Subject<boolean> = new Subject<boolean>();
    /**
     * Notifies when the Viewer should show UNAVAILABLE
     */
    public IsRequestUnavailable$: Subject<boolean> = new Subject<boolean>();

    private stringHelper = new StringHelper();

    // Accesable properties:
    /**
     * Sets the RequestGuid (as property) and retrieves associated DATA
     */
    public set setRequestGuid(requestGuid: string) {
        this._requestGuid = requestGuid;
        this.getRequestData(this._requestGuid);
    }


    public get get_RequestGuid(): string { return this._requestGuid; }
    public get get_RequestName(): string { return this._requestName; };
    public get get_RequestType(): string { return this._requestType; };
    public get get_RequestStatus(): string { return this._requestStatus; };

    public get get_User(): User { return this._user; };

    private ipAdress$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public getIpAdress$(): Observable<string> {
        return this.ipAdress$.asObservable();
    }

    private expectedRequestStatus$: BehaviorSubject<StatusEnum> = new BehaviorSubject<StatusEnum>(StatusEnum.Active);
    public getExpectedRequestStatus$(): Observable<StatusEnum> {
        return this.expectedRequestStatus$.asObservable();
    }

    private allActors$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([]);
    public getActors$(): Observable<DocumentActor[]> {
        return this.allActors$.asObservable();
    }
    private currentActor$: BehaviorSubject<DocumentActor> = new BehaviorSubject<DocumentActor>(new DocumentActor());
    /**
     * 
     * @returns NOTE: currentActor pushes FIRST an empty model, as this is a behavioursubject it MUST have an instance
     */
    public getCurrentActor$(): Observable<DocumentActor> {
        return this.currentActor$.asObservable();
    }
    private currentActors$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([]);
    public getCurrentActors$(): Observable<DocumentActor[]> {
        return this.currentActors$.asObservable();
    }

    private RequestAlreadyCompleted$: Subject<boolean> = new Subject<boolean>();
    public getRequestAlreadyCompleted$(): Observable<boolean> {
        return this.RequestAlreadyCompleted$.asObservable();
    }
    private completedAwaitingOtherActors$: Subject<boolean> = new Subject<boolean>();
    public getCompletedAwaitingOtherActors$(): Observable<boolean> {
        return this.completedAwaitingOtherActors$.asObservable();
    }
    private completed$: Subject<boolean> = new Subject<boolean>();
    public getCompleted$(): Observable<boolean> {
        return this.completed$.asObservable();
    }
    private completedAwaitingFiling$: Subject<boolean> = new Subject<boolean>();
    public getCompletedAwaitingFiling$(): Observable<boolean> {
        return this.completedAwaitingFiling$.asObservable();
    }
    // storage properties:
    private _requestGuid: string = "";
    private _requestName: string = "";
    private _requestType: string = "";
    private _requestStatus: StatusEnum = StatusEnum.Active;
    private _user!: User;

    private usersubscription: Subscription;
    private requestUpdatedSubscription: Subscription;


    // perform action properties:
    request: RequestModel = {} as RequestModel;
    documents: DocumentModel[] = [];
    documentsToShow: DocumentModel[] = [];
    documentsForDownloadList: DocumentModel[] = [];
    currentActor!: DocumentActor;
    allActors: DocumentActor[] = [];
    userActors: DocumentActor[] = [];
    otherActors: DocumentActor[] = [];

    rejectionReason!: string;
    dossierPersonId!: string;

    owner!: string;

    actions: DocumentActor[] = [];
    actionsOtherActors: DocumentActor[] = [];
    actionsToDo: DocumentActor[] = [];
    attachments: DocumentModel[] = [];

    receivers: any; // TODO: GIVE TYPE


    // OrganisationSettings
    settings: any[] = [];
    showLocation: boolean = false;
    showReason: boolean = false;
    showStamp: boolean = true;
    showDraw: boolean = true;
    showPicture: boolean = true;
    generateDC: boolean = false;


    signStartedForDocument: string | null = null;

    /** Digipoort environment as set in organization settings */
    setDigipoortEnvironmentForOrganisation!: string;
    /** SBR Nexus environment as set in organization settings */
    setSBRNexusEnvironmentForOrganisation!: string;
    /** Production environment Digipoort */
    digipoortProductionEnvironment: string = "productie";
    /** Production environment SBR Nexus */
    SBRNexusProductionEnvironment: string = "BTP";
    /**
     * We trigger the check two times, once when settings loaded, and once when Request loaded. (since we need both to validate)
     * Use this boolean to only display 1 message even if both could return true (strangely)
     */
    alreadyDisplayedEnvironmentWarning: boolean = false;
    /**
     * List of locked documents for toasts BUT WHICH WHERE NOT YET SHOWN
     */
    listOfLockedDocumentsWithoutToasties: DocumentIsLockedModel[] = [];
    /**
     * List of locked documents for toasts WHICH WERE SHOWN
     */
    listOfToastsWithLockedDocuments: DocumentInfoForToast[] = [];


    translations = <any>{};


    constructor(public router: Router, private dossierService: DossierService, public personService: PersonApiClient, private authenticationService: AuthenticationService,
        private requestService: RequestApiClient, private organisationSettingsApiClient: OrganisationSettingsApiClient, private viewerHubService: ViewerHubService,
        private translateService: TranslateService, private toastService: ToastService) {

        this.getTranslations();
        translateService.onLangChange.subscribe(() => {
            this.getTranslations();
        });
        this.usersubscription = this.authenticationService.currentUser.subscribe((data) => {
            this._user = data;
            this.getDetachedCertificateSetting();
            this.organisationSettingsApiClient.getOrganisationSettings(this._user.organisationGuid).subscribe((data) => {
                this.settings = data;
                if (this.settings.findIndex(os => os.key == "DigipoortEnvironment") > -1) this.setDigipoortEnvironmentForOrganisation = this.settings[this.settings.findIndex(os => os.key == "DigipoortEnvironment")].value;
                if (this.settings.findIndex(os => os.key == "SBRNexusEnvironment") > -1) this.setSBRNexusEnvironmentForOrganisation = this.settings[this.settings.findIndex(os => os.key == "SBRNexusEnvironment")].value;

                // this.checkIfEnvironmentNotInProductionMode();

                if (this.settings.findIndex(os => os.key == "ShowReason") > -1) this.showReason = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "ShowReason")].value);
                if (this.settings.findIndex(os => os.key == "ShowLocation") > -1) this.showLocation = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "ShowLocation")].value);
                if (this._user && this._user.isCustomer) {
                    if (this.settings.findIndex(os => os.key == "UseInternalStamp") > -1) this.showStamp = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseInternalStamp")].value);
                    if (this.settings.findIndex(os => os.key == "UseInternalDraw") > -1) this.showDraw = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseInternalDraw")].value);
                    if (this.settings.findIndex(os => os.key == "UseInternalPicture") > -1) this.showPicture = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseInternalPicture")].value);
                }
                else {
                    if (this.settings.findIndex(os => os.key == "UseExternalStamp") > -1) this.showStamp = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseExternalStamp")].value);
                    else this.showStamp = true;
                    if (this.settings.findIndex(os => os.key == "UseExternalDraw") > -1) this.showDraw = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseExternalDraw")].value);
                    else this.showDraw = false;
                    if (this.settings.findIndex(os => os.key == "UseExternalPicture") > -1) this.showPicture = JSON.parse(this.settings[this.settings.findIndex(os => os.key == "UseExternalPicture")].value);
                    else this.showPicture = false;
                }

            });
        });

        this.requestUpdatedSubscription = this.viewerHubService.viewerRequest$.subscribe(request => {
            if (!request || (!request.documentInfo && !request.actors && !request.status)) {
                return;

            }
            this.request = request;
            this.documents = this.request.documentInfo;
            this.allActors = this.request.actors;
            this.userActors = this.request.actors.filter(p => p.email == this._user.email && p.mobile == this._user.phoneNumber).sort((a, b) => a.order - b.order);
            this.userActors.forEach((actor, index) => {
                actor.badgeNumber = index + 1;
            });
            this.otherActors = this.request.actors.filter(x => x.action != ActorActionEnum.OptionalDownload).filter(p => p.email != this._user.email || p.mobile != this._user.phoneNumber).sort((a, b) => a.order - b.order);
            if (this.request.status == StatusEnum.Completed && this._requestStatus != StatusEnum.Completed) {
                this.completed$.next(true);
            }
            this._requestStatus = (this.request.status as StatusEnum);
            this.initializationDone$.next(true);

        });
    }

    getTranslations() {
        this.translateService.get([
            'Request.View.PerformActionComplete.PreparingDocument',
            'Request.View.PerformActionComplete.Signing',
            'Request.View.PerformActionComplete.Signed',
            'Request.View.PerformActionComplete.ErrorStartingSignflow',
            'Request.View.PerformActionComplete.GivePermissionQTSPApp',
            'Request.View.PerformActionComplete.PreparingFiling',
            'Request.View.PerformActionComplete.GeneratingSignature',
            'Error.DefaultErrorTitle',
            'Error.EngineErrorDefault',
            'Error.Engine.FailedDependency',
            'Request.View.SigningDialog.ESeal',
            'Request.View.SigningDialog.Token',
            'Request.View.SigningDialog.TokenAgent',
            'Request.View.SigningDialog.Test',
            'Toasts.RequestsDownloadError',
            'Toasts.GetDocumentError',
            'Toasts.DownloadTitle',
            'Toasts.DownloadBody',
            'Toasts.Error',
            'Request.View.ReceivingEntity.Digipoort',
            'Request.View.ReceivingEntity.SBRNexus'
        ]).subscribe(translation => {
            this.translations.PreparingDocument = translation['Request.View.PerformActionComplete.PreparingDocument'];
            this.translations.Signing = translation['Request.View.PerformActionComplete.Signing'];
            this.translations.Signed = translation['Request.View.PerformActionComplete.Signed'];
            this.translations.ErrorStartingSignflow = translation['Request.View.PerformActionComplete.ErrorStartingSignflow'];
            this.translations.GivePermissionQTSPApp = translation['Request.View.PerformActionComplete.GivePermissionQTSPApp'];
            this.translations.PreparingFiling = translation['Request.View.PerformActionComplete.PreparingFiling'];
            this.translations.GeneratingSignature = translation['Request.View.PerformActionComplete.GeneratingSignature'];
            this.translations.DefaultErrorTitle = translation['Error.DefaultErrorTitle'];
            this.translations.EngineErrorDefault = translation['Error.EngineErrorDefault'];
            this.translations.FailedDependency = translation['Error.Engine.FailedDependency'];
            this.translations.ESeal = translation['Request.View.SigningDialog.ESeal'];
            this.translations.Token = translation['Request.View.SigningDialog.Token'];
            this.translations.TokenAgent = translation['Request.View.SigningDialog.TokenAgent'];
            this.translations.Test = translation['Request.View.SigningDialog.Test'];
            this.translations.toastsRequestsDownloadError = translation['Toasts.RequestsDownloadError'];
            this.translations.toastsGetDocumentError = translation['Toasts.GetDocumentError'];
            this.translations.error = translation['Toasts.Error'];
            this.translations.DownloadTitle = translation['Toasts.DownloadTitle'];
            this.translations.DownloadBody = translation['Toasts.DownloadBody'];
            this.translations.Digipoort = translation['Request.View.ReceivingEntity.Digipoort'];
            this.translations.SBRNexus = translation['Request.View.ReceivingEntity.SBRNexus'];
        });
    }


    ngOnDestroy() {
        if (this.usersubscription) {
            this.usersubscription.unsubscribe();
        }
        if (this.requestUpdatedSubscription) {
            this.requestUpdatedSubscription.unsubscribe();
        }
    }



    /**
     * Creates the identifier for action fields, this can be used to navigate to, and to display badge at position
     * @param documentId 
     * @param fieldName 
     * @returns 
     */
    createSigningFieldId(documentId: string, fieldName: string): string {
        return documentId + fieldName;
    }

    /**
     *  This sets fields which normally are set/updated by Backend but we should also keep them set for Frontend during actions
     * @param inviteId 
     * @param adoptionChoice The choice that was picked during Adopt action.
     */
    public setFieldsOnActor(inviteId: string, adoptionChoice: boolean) {
        let matchedActor = this.allActors.find(x => x.id == inviteId);
        if (matchedActor) {
            matchedActor.adoptionChoice = adoptionChoice
        }
    }
    /**
     * An Actor action returned OK
     * @param inviteId 
     */
    public actorCompletedAction(inviteId: string) {
        let matchedActor = this.allActors.find(x => x.id == inviteId);
        if (matchedActor) {
            matchedActor.hasSigned = true
            matchedActor.signingDate = new Date();
        }
        this.allActors$.next(this.allActors);
        this.otherActors = this.request.actors.filter(x => x.action != ActorActionEnum.OptionalDownload).filter(p => p.email != this._user.email || p.mobile != this._user.phoneNumber).sort((a, b) => a.order - b.order);
        this.userActors = this.allActors.filter(p => p.email == this._user.email && p.mobile == this._user.phoneNumber).sort((a, b) => a.order - b.order);
        this.currentActors$.next(this.userActors);
        let nextActors = this.userActors.filter(x => !x.hasSigned).sort((a, b) => a.order - b.order);
        if (nextActors && nextActors.length > 0) {
            this.currentActor$.next(nextActors[0]);
            return;
        } else {
            // all your actions are done:
            if (this.otherActors.some(x => !x.hasSigned)) {
                this.completedAwaitingOtherActors$.next(true);
            } else {
                if (this.userActors.some(x => x.action == ActorActionEnum.File)) {
                    this.completedAwaitingFiling$.next(true);
                } else {
                    this.completed$.next(true);
                }
            }
        }
    }

    public runTimerForLockedDocument(document: DocumentIsLockedModel) {
        let lockingInterval = setInterval(() => {
            document.Seconds = document.Seconds - 1;
            // your document is no longer in the locked arrays
            if (!ExistsIn(this.listOfToastsWithLockedDocuments, "Guid", document.Guid) && !ExistsIn(this.listOfLockedDocumentsWithoutToasties, "Guid", document.Guid)) {
                clearInterval(lockingInterval);
                return;
            }
            // your time passed, request new status and clear this one
            if (document.Seconds < 1) {
                this.viewerHubService.SignStatus(document.Guid);
                clearInterval(lockingInterval);
            }
        }, 1000);
    }

    private getRequestData(forRequestGuid: string) {
        this.requestService.getRequestForViewer(forRequestGuid).subscribe({
            next: (dossier: RequestModel) => {
                this.request = dossier;
                this._requestName = this.request.jobName;
                this._requestType = this.request.dossierType;
                this.documents = this.request.documentInfo;
                this.allActors = this.request.actors;
                this._requestStatus = (this.request.status as StatusEnum);
                this.expectedRequestStatus$.next(this._requestStatus);
                this.userActors = this.request.actors.filter(p => p.email == this._user.email && p.mobile == this._user.phoneNumber).sort((a, b) => a.order - b.order);
                this.otherActors = this.request.actors.filter(x => x.action != ActorActionEnum.OptionalDownload).filter(p => p.email != this._user.email || p.mobile != this._user.phoneNumber).sort((a, b) => a.order - b.order);
                this.userActors.forEach((actor, index) => {
                    actor.badgeNumber = index + 1;
                });
                if (!this.userActors || this.userActors.length <= 0) {
                    // no actors!!??
                    this.IsRequestUnavailable$.next(true);
                    return;
                }
                if (this.userActors.every(x => x.hasSigned)) {
                    // you are already done
                    if (this.otherActors.some(x => !x.hasSigned)) {
                        this.completedAwaitingOtherActors$.next(true);
                    } else {
                        this.RequestAlreadyCompleted$.next(true);
                    }
                }
                this.documentsToShow = this.documents.filter(s => s.documentType.toLocaleLowerCase() != "html" && s.documentType.toLocaleLowerCase() != "generictextfile" && s.documentType.toLocaleLowerCase() != "detachedsignature");
                this.documentsForDownloadList = this.documents;
                this.allActors$.next(this.allActors);
                if (this.userActors.some(x => !x.hasSigned)) {
                    this.currentActor$.next(this.userActors.filter(x => !x.hasSigned)[0]);
                }
                this.currentActors$.next(this.userActors);

                this.getRequest();
            }, error: () => { this.initializationFailed$.next(true); }
        });
    }

    private getRequest() {
        if (this.request == undefined) {
            this.initializationFailed$.next(true);
            return;
        }
        if (this.request != undefined) {
            let errorStatusses: string[] = [StatusEnum.Error, StatusEnum.Expired, StatusEnum.InvitationExpired, StatusEnum.Withdrawn];
            // IS THE REQUEST IN A FAILED STATE!!???
            if (errorStatusses.includes(this.request.status)) {
                // in a VERY VERY specific scenario we do want to allow (externals) to download a request
                // specific being: request was completed, purged/cleaned up and we are now still wanting to download it
                if (
                    // the request MUST be currently 'Expired' specifically
                    this.request.status == StatusEnum.Expired &&
                    // the previous status must have been 'Completed'
                    this.request.previousStatus == StatusEnum.Completed) {
                    // specific switch to download page so persons can STILL download a purged request
                    this.initializationDone$.next(true);
                    this.RequestAlreadyCompleted$.next(true);
                    return;
                } else {
                    // else NORMAL behavior and show unavailable
                    this.initializationFailed$.next(true);
                    return;
                }
                // NORMAL BEHAVIOUR, FOR A REQUEST IN A NORMAL STATE
            } else {
                if (this.request.status === StatusEnum.Declined) {
                    this.rejectionReason = this.request.reasons;
                }
                this.currentActor = this.request.actors.filter(p => p.email == this._user.email && p.mobile == this._user.phoneNumber)[0];
                this.dossierPersonId = this.currentActor.dossierPersonId;
                this.GetActions();
            }
        }

        if (this.request.dossierType === 'SbrNexusAnnualReport' || this.request.dossierType === 'SbrNexusAssurance' || this.request.dossierType === 'NoabReport') {
            this.GetReceiversForSbrNexus();
        }
    }

    private GetActions() {
        if (this.get_RequestGuid != undefined && this.dossierPersonId != undefined) {
            this.actionsOtherActors = this.request.actors.filter(a => a.dossierPersonId !== this.dossierPersonId && !a.hasSigned).sort((a, b) => a.order - b.order);

            this.personService.getDossierPersonWithActions(this.get_RequestGuid, this.dossierPersonId)
                .subscribe({
                    next: (value: any) => {
                        this.owner = value.dossierOwnerName;
                        this.ipAdress$.next(value.ipAdress);
                        this.actions = value.actions;
                        this.actionsToDo = this.actions.filter(a => !a.hasSigned).sort((a, b) => a.order - b.order);

                        // Overwrite order-field with actual order, starting with 1
                        let lowestTemp = this.actions.reduce((previous, current) => {
                            return current.order < previous.order ? current : previous;
                        });
                        let lowest = { ...lowestTemp };
                        this.actions.forEach((action) => {
                            action.order = action.order - lowest.order + 1;
                        });
                        this.actions = this.actions.filter(a => a.email == this._user.email && a.mobile == this._user.phoneNumber).sort((a, b) => a.order - b.order);
                        this.attachments = this.request.documentInfo.filter(p => p.actors.length === 0 && p.documentType != 'DetachedSignature' && p.documentType != 'GenericTextFile');

                        let firstActor = this.request.actors.reduce((prev, curr) => prev.order < curr.order ? prev : curr);
                        let ownerIsFirstActor = firstActor.email == this.request.documentOwner.email && firstActor.mobile == this.request.documentOwner.mobile;
                        if (this.request?.documentOwner?.userId !== this._user.sub || !ownerIsFirstActor) {
                            // this.openActionSummaryModal();
                        }
                        this.initializationDone$.next(true);
                        this.checkIfEnvironmentNotInProductionMode();
                    },
                    error: () => {
                        this.initializationFailed$.next(true);
                        return;
                    }
                });
        }
    }


    getDetachedCertificateSetting() {
        this.organisationSettingsApiClient.getDetachedCertificate(this._user.organisationGuid).subscribe({
            next: (settingData: any) => {
                this.generateDC = settingData.includeDetachedCertificate;
            },
        });
    }

    private GetReceiversForSbrNexus() {
        this.dossierService.getReceivers(this.get_RequestGuid).subscribe({
            next: (data: any) => {
                this.receivers = data;
            },
            error: () => {
                this.initializationFailed$.next(true);
                return;
            }
        });
    }


    /**
     * This checks whether the request will (probaly) be filed (based on request Type)
     * and if so, also verifies whether the set Environment of Digipoort/SbrNexus is NOT set to production
     * Shows a PERMANENT (x able) Toast if NOT production
     */
    private checkIfEnvironmentNotInProductionMode() {
        if (this.request.dossierType === 'IncomeTaxDeclaration' ||
            this.request.dossierType === 'IcpDeclaration' ||
            this.request.dossierType === 'VatDeclaration' ||
            this.request.dossierType === 'CorporateTaxDeclaration' ||
            this.request.dossierType === 'FinancialStatement' ||
            this.request.dossierType === 'FinancialStatements' ||
            this.request.dossierType === 'SbrAssurance' ||
            this.request.dossierType === 'AnnualFinancialFiles') {
            if (this.isDigipoortNonProduction() && !this.alreadyDisplayedEnvironmentWarning && this.setDigipoortEnvironmentForOrganisation) {
                this.alreadyDisplayedEnvironmentWarning = true;
                this.toastService.displayEnvironmentWarning(this.translations.Digipoort, this.setDigipoortEnvironmentForOrganisation);
            }
        } else if (this.request.dossierType === 'SbrNexusAnnualReport' ||
            this.request.dossierType === 'SbrNexusAssurance' ||
            this.request.dossierType === 'NoabReport') {
            if (this.isSBRNexusNonProduction() && !this.alreadyDisplayedEnvironmentWarning && this.setDigipoortEnvironmentForOrganisation) {
                this.alreadyDisplayedEnvironmentWarning = true;
                this.toastService.displayEnvironmentWarning(this.translations.SBRNexus, this.setSBRNexusEnvironmentForOrganisation);
            }
        }
    }
    private isDigipoortNonProduction() {
        // current SETTING environment is not equal production environment
        return this.setDigipoortEnvironmentForOrganisation !== this.digipoortProductionEnvironment;
    }

    private isSBRNexusNonProduction() {
        // current environment is not equal production environment
        return this.setSBRNexusEnvironmentForOrganisation !== this.SBRNexusProductionEnvironment;
    }
}