<div class="me-4">
  <button *ngIf="(downloadAllowed() || exportAllowed())" class="btn btn-sm btn-primary dropdown-toggle w-auto" type="button" data-bs-toggle="dropdown"
      aria-expanded="false" translate="Request.Status.RequestOptions.DownloadRequest"></button>
  <div class="dropdown float-end">
    <ul class="dropdown-menu w-auto">
      <li *ngIf="downloadAllowed()" ><a class="dropdown-item" (click)="applyAction(requestStatusActionsEnum.Download)" translate="Request.Status.RequestOptions.ToDevice"></a></li>
      <li *ngIf="exportAllowed() && showMSplugins"><a class="dropdown-item" (click)="applyAction(requestStatusActionsEnum.Export)" translate="Request.Status.RequestOptions.ToCloud"></a></li>
    </ul>
  </div>
  <button *ngIf="withdrawAllowed()" (click)="applyAction(requestStatusActionsEnum.Withdrawn)" class="btn btn-sm btn-outline-danger w-auto ms-2" type="button" translate="Request.Status.RequestOptions.Withdraw"></button>
  <button *ngIf="purgeAllowed()" (click)="applyAction(requestStatusActionsEnum.Purge)" class="btn btn-sm btn-outline-secondary w-auto ms-2" type="button" translate="Request.Status.RequestOptions.Purge"></button>
  <button *ngIf="restoreAllowed()" (click)="applyAction(requestStatusActionsEnum.Restore)" class="btn btn-sm btn-success w-auto ms-2" type="button" translate="Request.Status.RequestOptions.Restore"></button>
  <button *ngIf="deleteAllowed()" (click)="applyAction(requestStatusActionsEnum.Delete)" class="btn btn-sm btn-danger w-auto ms-2" type="button" translate="Request.Status.RequestOptions.Delete"></button>
  <button *ngIf="becomeOwnerAllowed()" (click)="applyAction(requestStatusActionsEnum.BecomeOwner)" class="btn btn-sm btn-outline-secondary w-auto ms-2" type="button" translate="Request.Status.RequestOptions.BecomeOwner"></button>
</div>