import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { DocumentModel, PreloadedPages, PreloadedPagesByDocument } from "src/app/models/document.model";
import { LoggingService } from "src/app/services/logging.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PagesApiClient } from "src/app/services/api-clients/pages.api-client";
import { SingleOrDefault } from "../_helpers/ArrayExtensionMethodHelper.functions";

@Injectable({ providedIn: 'root' })
export class DocumentPagesService {

    private _alreadyLoadedPagesByDocument: PreloadedPagesByDocument[] = [];

    constructor(private loggingService: LoggingService, private sanitizer: DomSanitizer,
        private pageService: PagesApiClient) { }

    public async getAlreadyLoadedPages(requestGuid: string, documentGuid: string): Promise<PreloadedPagesByDocument> {
        // existing?
        let documentPagesModel = SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid);
        if (documentPagesModel) {
            return documentPagesModel;
        }
        // instantiate the FIRST page and return:
        await this.requestPage(requestGuid, documentGuid, 1);
        return SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid)!;
    }

    public async resetAlreadyLoadedPagesAndReLoadThem(requestGuid: string, documentGuid: string) {
        // existing?
        let documentPagesIndex = this._alreadyLoadedPagesByDocument.findIndex(s => s.documentId === documentGuid);
        if(documentPagesIndex > -1) {
            this._alreadyLoadedPagesByDocument[documentPagesIndex].preloadedPages = [];
        }
        this.getAlreadyLoadedPages(requestGuid, documentGuid);
    }

    public async requestPage(requestGuid: string, documentGuid: string, pageId: number): Promise<PreloadedPagesByDocument> {
        let documentPagesModel = SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid);
        if (documentPagesModel) {
            var existingPage = SingleOrDefault(documentPagesModel.preloadedPages, "PageId", pageId);
            if (existingPage) {
                return documentPagesModel;
            }
        }else{
            documentPagesModel = new PreloadedPagesByDocument();
            documentPagesModel.documentId = documentGuid;
            documentPagesModel.preloadedPages = [];
        }


        let getPage = await this.pageService.getPage(requestGuid, documentGuid, pageId);
        let page = await lastValueFrom(getPage);
        let pageImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page));
        let preloadedPage: PreloadedPages = {
            PageId: pageId,
            PageUrl: pageImage
        }
        documentPagesModel.preloadedPages.push(preloadedPage);
        this._alreadyLoadedPagesByDocument.push(documentPagesModel);
        return documentPagesModel;
    }
}
