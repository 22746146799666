import { Directive, Input } from '@angular/core';
import { BaseTableClass } from './base_table.class';
import { RequestStatusService } from '../../requeststatus/requeststatus.service';
import { TableColumn } from 'src/app/models/tableColumn';
import { VerifyRequestActionService } from 'src/app/services/verify-request-action.service';

// needed for angular events (Input)
@Directive()
export class BaseTableVerticalClass extends BaseTableClass {
  protected override _displayedColumns: TableColumn[] = [];
  public override columnsToDisplay: string[] = [];
  @Input() override set displayedColumns(columns: TableColumn[]) {
    this._displayedColumns = columns;
  }
  override get displayedColumns(): TableColumn[] {
    this.columnsToDisplay = this._displayedColumns.map((s: TableColumn) => s.columnName);
    return this._displayedColumns;
  }
  constructor(protected override requestStatusService: RequestStatusService, protected override verifyRequestActionService: VerifyRequestActionService) {
    super(requestStatusService, verifyRequestActionService);
  }
}

