import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentModel, PageSize, Thumbnail } from 'src/app/models/document.model';
import { BuilderRequest } from 'src/app/models/requests';
import { OrganisationApiClient } from 'src/app/services/organisation.apiclient';
import { User } from 'src/app/models/user';
import { lastValueFrom, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PagesApiClient } from 'src/app/services/api-clients/pages.api-client';
import { LoggingService } from 'src/app/services/logging.service';
import { DocumentApiClient } from 'src/app/services/document.apiclient';
import { AddStationeryModalComponent } from '../add-stationery-modal/add-stationery-modal.component';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalOpenerService } from 'src/app/modals/modal-opener.service';
import { OrganisationPlugins } from 'src/app/constants/settings.enum';
import { DocumentPagesService } from 'src/app/services/document-pages.service';

@Component({
  selector: 'app-edit-document-modal',
  templateUrl: './edit-document-modal.component.html'
})
export class EditDocumentModalComponent implements OnInit {
  @Input() document!: DocumentModel;
  @Input() pageSizes: PageSize[] = [];
  @Input() request!: BuilderRequest;
  @Output() getRequest = new EventEmitter();
  @Output() loadThumbnail = new EventEmitter();
  @Output() forceLoadThumbnail = new EventEmitter();
  fieldsDisabled: boolean = false;
  pageId: number = 1;
  scrollHeight!: any;
  selectedPages: number[] = [];
  dropdownText: string = 'Request.Document.Dialog.SelectPagesFirst';
  selectedAction: Actions | null = null;
  showFileSelector = (): boolean => this.selectedAction === Actions.AddDocumentAfterPage || this.selectedAction === Actions.AddDocumentBeforePage;
  showDeleteButton = (): boolean => this.selectedAction === Actions.DeletePages;
  showStationeryButton = (): boolean => this.selectedAction === Actions.AddStationery;
  plugins: any[] = [];
  neededPlugins: any[] = [OrganisationPlugins.SharePoint, OrganisationPlugins.OneDrive, OrganisationPlugins.Teams];
  user!: User;
  usersubscription: Subscription;
  cloudlocations: any;
  showMSplugins: boolean = false;
  translations:any = <any>{};

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private organisationApiClient: OrganisationApiClient, private authenticationService: AuthenticationService,
    private pageService: PagesApiClient, private loggingService: LoggingService, private documentService: DocumentApiClient,  private toastService: ToastService, private translateService: TranslateService,
    private modalOpenerService: ModalOpenerService, private documentPagesService: DocumentPagesService) { 
      this.getTranslations();
      translateService.onLangChange.subscribe(() => {
        this.getTranslations();
      });
      this.usersubscription = this.authenticationService.currentUser.subscribe((data:any) => {
      this.user = data;
    });
    this.organisationApiClient.getEnabledPlugins(this.user.organisationGuid).subscribe({
      next: (data:any) => {
        this.plugins = data;
        if (this.plugins.filter(s => this.neededPlugins.includes(s.code)).length>0) {
          this.showMSplugins = true;
        }
      }
    });
  }

  getTranslations() {
    this.translateService.get([
      'Toasts.Error',
      'CloudLocations.ImportFilesFailed'
    ]).subscribe(translation => {
      this.translations.error = translation['Toasts.Error'];
      this.translations.importFilesFailed = translation['CloudLocations.ImportFilesFailed'];
    });
  }

  ngOnInit(): void {
    for (let pageSize of this.pageSizes) {
      pageSize.Y = (pageSize.Y/pageSize.X)*110;
      pageSize.X = 110;
    }

    this.scrollHeight = document.querySelector(".edit-document-list");      
    this.scrollHeight.addEventListener('scroll', () => {
      this.scroll();
    });

    // Empty preloadedPages, so after changes the pdf viewers are always working with newest pages.
    this.document.preloadedPages = [];
    this.loadInitialPages();
  }

  loadInitialPages() {
    for(let i = 1; i <= 20; i++){
      if (i <= 0 || i > this.document.pages) continue;
      this.loadThumbnail.emit(i);
    }
  }

  forceLoadThumbnails(from: number, to: number) {
    for(let i = from; i <= to; i++){
      if (i <= 0 || i > this.document.pages) continue;
      this.forceLoadThumbnail.emit(i);
    }
  }

  async close() {
    await this.documentPagesService.resetAlreadyLoadedPagesAndReLoadThem(this.request.id, this.document.id);
    this.activeModal.close();
  }

  async OnFileSelected(event:any){
    event.preventDefault();
    event.stopPropagation();
    let filelist: FileList = event.target.files;
    if (filelist == undefined){filelist= event.dataTransfer.files}
    if (filelist.length < 1) return;
    let fileArray: File[] = Array.from(filelist); 

    // Set event.target.value to null, so a file with the same name can be uploaded after deleting it from the request.
    event.target.value = null;
        
    this.document.thumbnails.sort((a, b) => a.PageId - b.PageId);
    let selectedPage = this.selectedPages[0];
    if (this.selectedAction === Actions.AddDocumentAfterPage) selectedPage++;
    this.removeThumbnailsAfter(selectedPage);
    
    this.disableForm();
    this.insertPageBefore(fileArray[0], selectedPage);
  }

  private insertPageBefore(file: File, selectedPage:number) {
    let formData = new FormData();
    formData.set('file', file, file.name);
    this.pageService.insertPageBefore(this.request.id, this.document.id, selectedPage, formData)
    .then((fileUpload: any) => {
      lastValueFrom(fileUpload)
      .then(async () => {
        await this.getDocument(this.document, selectedPage);
        this.enableForm();
      })
      .catch((error) => {
        this.loggingService.logException(error);
        this.enableForm();
      });
    }).catch((error) => {
      this.loggingService.logException(error);
      this.enableForm();
    });
  }

  private removeThumbnailsAfter(selectedPage: number) {
    this.document.thumbnails.splice(selectedPage-1, this.document.thumbnails.length - selectedPage + 1);
  }

  async getDocument(doc:DocumentModel, loadFromPage:number | undefined) {
    try {
      let documentRequest = await this.documentService.getDocumentForViewer(this.request.id, doc.id);
      let document = await lastValueFrom(documentRequest) as DocumentModel;
      if(document === null) return;
      this.document.pages = document.pages;
      this.document.pageSizes = document.pageSizes;
      this.setPageSizes();

      if (!loadFromPage) {
        this.document.thumbnails = [];
        this.loadInitialPages();
      } else {
        this.forceLoadThumbnails(loadFromPage, this.document.pages);
      }
    } catch (error:any) {
      this.loggingService.logException(error)
    }
  }

  private setPageSizes() {
    this.pageSizes = [];
    JSON.parse(this.document.pageSizes).forEach((pageSize: PageSize) => {
      pageSize.Y = (pageSize.Y / pageSize.X) * 110;
      pageSize.X = 110;
      this.pageSizes.push(pageSize);
    });
  }

  addDocumentsCloudLocation() {
    this.document.thumbnails.sort((a, b) => a.PageId - b.PageId);
    let selectedPage = this.selectedPages[0];
    if (this.selectedAction === Actions.AddDocumentAfterPage) selectedPage++;
    
    const modalRef = this.modalOpenerService.Open_CloudDocumentUpload(this.request.id, this.plugins, ['pdf'], false);
    modalRef.componentInstance.setUrl.subscribe((requestId: string) => {
      this.getRequest.emit(requestId);
    });
    modalRef.componentInstance.close.subscribe(async (event:any) => {
      modalRef.close();
      if (event && event.externalFiles && event.externalFiles.length > 0) {
        try {
          this.disableForm();
          this.removeThumbnailsAfter(selectedPage);
          let importChildContents = await this.pageService.importPageBefore(this.request.id, this.document.id, selectedPage, event.provider, event.id, event.externalFiles[0]);
          await lastValueFrom(importChildContents);
                    
          await this.getDocument(this.document, selectedPage);
          this.enableForm();
        } catch (error: any) {
          this.loggingService.logException(error);
          this.toastService.showDefault(this.translations.error, this.translations.importFilesFailed);
        }
      }
    });
  }

  elementInViewport(pageElement: HTMLElement) {
    let bounding = pageElement.getBoundingClientRect();
    let list = document.getElementById("edit-document-list");
    if (!list) return false;
    let listBounding = list.getBoundingClientRect();
    return !(bounding.bottom < listBounding.top || bounding.top > listBounding.bottom);
  }

  scroll(){
    for (let pageId: number = 1; pageId <= this.document.pages; pageId++) {
      let pageElement: HTMLElement = <HTMLElement>document.getElementById("page_" + pageId);
      if (this.elementInViewport(pageElement)) {
        for(let i = pageId; i <= pageId+8; i++){
          if (i <= 0 || i > this.document.pages) continue;
          this.loadThumbnail.emit(i);
        }
      }
    }
  }

  getPageUrl(pageId: number){
    return this.document.thumbnails?.find((s: Thumbnail) => s.PageId === pageId)?.ThumbnailUrl;
  }

  checkIfItemIsRendered(pageId: number) {
    if(this.document.thumbnails?.findIndex((s: Thumbnail) => s.PageId === pageId) > -1 && this.document.thumbnails?.find((s: Thumbnail) => s.PageId === pageId)?.ThumbnailUrl !== null) return true;
    return false;
  }

  changeSelection(pageNumber: number) {
    if (this.selectedPages.indexOf(pageNumber) < 0) this.selectedPages.push(pageNumber);
    else this.selectedPages.splice(this.selectedPages.indexOf(pageNumber), 1);
    
    this.setDropdownAndButtonContent();   
  }

  setDropdownAndButtonContent() {
    if (this.selectedPages.length === 0) {
      this.dropdownText = 'Request.Document.Dialog.SelectPagesFirst';
      this.selectedAction = null;
    } else if(this.selectedPages.length !== 1 && (this.selectedAction == Actions.AddDocumentAfterPage || this.selectedAction == Actions.AddDocumentBeforePage)) {
      this.selectedAction = null;
      this.dropdownText = 'Request.Document.Dialog.SelectAction';
    } else if (!this.selectedAction) {
      this.dropdownText = 'Request.Document.Dialog.SelectAction';
    } 
  }

  selectAll() {
    for (let i = 1; i <= this.document.pages; i++) {
      if (this.selectedPages.indexOf(i) == -1) {
        let element = document.getElementById('pageInput_' + i) as HTMLInputElement | null;
        if (element) element.checked = true;
        this.selectedPages.push(i);
      }
    }
  }

  deselectAll() {
    for (let i = 1; i <= this.document.pages; i++) {
      if (this.selectedPages.indexOf(i) != -1) {
        let element = document.getElementById('pageInput_' + i) as HTMLInputElement | null;
        if (element) element.checked = false;
      }
    }
    this.selectedPages = [];
    document.getElementById('actionDropdown')!.classList.remove('show');
  }

  deletePages() {
    this.selectedPages.forEach((page) => {
      this.document.thumbnails.sort((a,b) => a.PageId - b.PageId);
      let index = this.document.thumbnails.findIndex(p => p.PageId == page);
      this.document.thumbnails.splice(index, 1);
    });
    let lowestPageNumber = Math.min(...this.selectedPages);
    let selectedPages = [...this.selectedPages];
      
    this.disableForm();
    this.pageService.deletePages(this.request.id, this.document.id, selectedPages)
    .then((fileUpload: any) => {
      lastValueFrom(fileUpload)
      .then(async () => {
        this.enableForm();
        
        await this.getDocument(this.document, lowestPageNumber);
      });
    }).catch((error) => {
      this.enableForm();
      this.loggingService.logException(error);
    });
  }

  disableForm() {
    this.toggleScrolling(false);
    this.selectedPages = [];
    this.selectedAction = null;
    this.fieldsDisabled = true;
    this.setDropdownAndButtonContent();
  }
  enableForm() {
    this.toggleScrolling(true);
    this.fieldsDisabled = false;
  }

  toggleScrolling(enable: boolean) {
    let documentListElement = document.getElementById('edit-document-list');
    if (documentListElement) documentListElement.style.overflowY = enable ? 'scroll': 'hidden';
  }

  openStationeryModal() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      size: 'editdocument'
    };
    const documentModalRef = this.modalService.open(AddStationeryModalComponent, ngbModalOptions);
    documentModalRef.componentInstance.close.subscribe((selectedStationeryId: string) => {
      documentModalRef.close();
      let pagesToChange: any[] = [];
      this.selectedPages.forEach((page: number) => {
        this.document.thumbnails.sort((a,b) => a.PageId - b.PageId);
        let index = this.document.thumbnails.findIndex(p => p.PageId == page);
        this.document.thumbnails.splice(index,1);
        pagesToChange.push({
          pageNr: page,
          StationeryId: selectedStationeryId
        });
      });
      this.disableForm();
      this.changeBackgrounds(pagesToChange);

      this.dropdownText = 'Request.Document.Dialog.SelectAction';
    });
  }

  private async changeBackgrounds(pagesToChange: any[]) {
    try {
      let changeBackgroundCall = await this.pageService.changeBackground(this.request.id, this.document.id, pagesToChange);
      await lastValueFrom(changeBackgroundCall);
      for (let page of pagesToChange) {
        let pageElement: HTMLElement = <HTMLElement>document.getElementById("page_" + page.pageNr);
        if (this.elementInViewport(pageElement)) {
          this.forceLoadThumbnail.emit(page.pageNr);
        }
      }
      this.enableForm();
    } catch (error: any) {
      this.enableForm();
      this.loggingService.logException(error)
  }
  }

  selectAddDocumentBeforePage() {
    this.selectedAction = Actions.AddDocumentBeforePage;
    this.dropdownText = 'Request.Document.Dialog.AddDocumentBeforePage';
  }
  selectAddDocumentAfterPage() {
    this.selectedAction = Actions.AddDocumentAfterPage;
    this.dropdownText = 'Request.Document.Dialog.AddDocumentAfterPage';
  }
  selectDeletePages() {
    this.selectedAction = Actions.DeletePages;
    this.dropdownText = 'Request.Document.Dialog.DeletePages';
  }
  selectAddStationery() {
    this.selectedAction = Actions.AddStationery;
    this.dropdownText = 'Request.Document.Dialog.AddStationery';
  }
}

enum Actions {
  AddDocumentBeforePage,
  AddDocumentAfterPage,
  DeletePages,
  AddStationery
}
