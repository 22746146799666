<div class="rounded-corners">
<table class="mat-mdc-table infoBox">
  <tr class="mat-mdc-header-row">
    <th class="mat-mdc-header-cell mdc-data-table__header-cell" translate="Request.Status.RequestDetails"></th>
    <th class="mat-mdc-header-cell mdc-data-table__header-cell"></th>
  </tr>
  <div class="m-2 ms-3">
    <ng-container *ngFor="let column of displayedColumns">
      <div class="cols-12 row" *ngIf="column.columnName !== 'status'">
        <span class="col-4 text-secondary info subject d-block" translate="Table.{{column.transTag}}"></span>
        <span class="col-8 info d-block">
          {{ tableData[column.columnName] | valueByTypePipe : column.type }}
          <!-- Add copy to clipboard function, based on datatype Guid -->
          <div *ngIf="column.type === 'Guid'" class="ms-2 copy-icon">
            <em class="bi bi-copy cursor-pointer " (click)="copyToClipboard(tableData[column.columnName])"></em>
          </div>
        </span>
      </div>
      <!-- <tr class="mat-mdc-row cols-12" *ngIf="column.columnName !== 'status'">
        <td class="mat-mdc-cell mdc-data-table__cell col-4" translate="Table.{{column.transTag}}"></td>
        <td class="mat-mdc-cell mdc-data-table__cell col-8 fw-bold">
          <app-status-display *ngIf="column.columnName === 'status'" [status]="tableData.status"
            [displayStatusString]="false" [displayStatusOrb]="true" class="badge-status-table"></app-status-display>
          {{ tableData[column.columnName] | valueByTypePipe : column.type }} -->
          <!-- Add copy to clipboard function, based on datatype Guid -->
          <!-- <ng-container *ngIf="column.type === 'Guid'">
            <em class="bi bi-copy cursor-pointer copy-icon" aria-hidden="true" (click)="copyToClipboard(tableData[column.columnName])"></em>
          </ng-container>
        </td>
      </tr> -->
    </ng-container>
  </div>
</table>
</div>