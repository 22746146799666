import { Component, Directive, Input, OnDestroy } from '@angular/core';
import { BaseTableVerticalClass } from './base_table_vertical.class';
import { RequestStatusService } from '../../requeststatus/requeststatus.service';
import { Subscription } from 'rxjs';
import { RequestStatuses } from 'src/app/constants/status.enum';
import { VerifyRequestActionService } from 'src/app/services/verify-request-action.service';

@Directive()

export class StatusInfoTableClass extends BaseTableVerticalClass implements OnDestroy {

  @Input() override tableData: any;
  private : boolean = false;
  public statusIndicator!: string;
  public tableName = "Info";
  public hasEditRights: boolean = false;
  protected override requestSubscription!: Subscription;

  constructor(
    protected override requestStatusService: RequestStatusService, protected override verifyRequestActionService: VerifyRequestActionService) {
    super(requestStatusService, verifyRequestActionService);
    this.requestSubscription = this.requestStatusService.selectedRequest$.subscribe(request => {
      if (Object.keys(request).length === 0) return;
      this.hasEditRights = this.requestStatusService.hasUserEditRights();
    });
  }

  
  public getStatusIndicator() {
    let primaryStatuses: string[] = RequestStatuses.PrimaryStatuses;
    let warningStatuses: string[] = RequestStatuses.WarningStatuses;
    let successStatuses: string[] = RequestStatuses.SuccessStatuses;
    let errorStatuses: string[] = RequestStatuses.ErrorStatuses;

    if (errorStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "danger";
    }
    if (successStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "success";
    }

    if (primaryStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "primary";
    } else if (warningStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "warning";
    } else if (successStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "success";
    } else if (errorStatuses.includes(this.tableData.status)) {
      this.statusIndicator = "danger";
    } else {
      this.statusIndicator = "warning";
    }
  }

  public statusIndicatorProgress(): number {
    this.getStatusIndicator();
    let errorStatuses: string[] = RequestStatuses.ErrorStatuses;
    if (this.tableData.progress) {
      return parseInt(this.tableData.progress);
    }
    if (errorStatuses.includes(this.tableData.status)) {
      return 100;
    }
    return 0;
  }

  override copyToClipboard(Guid: string) {
    this.requestStatusService.copyToClipboard(Guid);
  }
}

@Component({
  selector: 'mobile-status-info-table',
  templateUrl: './mobile_table_status_info.component.html',
  styleUrls: ['./table.component.scss']
})

export class StatusInfoTableMobileComponent extends StatusInfoTableClass {
  constructor(
    protected override requestStatusService: RequestStatusService, protected override verifyRequestActionService: VerifyRequestActionService) {
    super(requestStatusService, verifyRequestActionService);
  }
}

@Component({
  selector: 'desktop-status-info-table',
  templateUrl: './desktop_table_status_info.component.html',
  styleUrls: ['./table.component.scss']
})

export class StatusInfoTableDesktopComponent extends StatusInfoTableClass {
  constructor(
    protected override requestStatusService: RequestStatusService, protected override verifyRequestActionService: VerifyRequestActionService) {
    super(requestStatusService, verifyRequestActionService);
  }
}