import { Component, Input } from '@angular/core';
import { StatusEnum } from 'src/app/constants/status.enum';
import { BadgeStatusEnum } from '../../constants/badge-status.enum';

@Component({
    selector: 'app-status-display',
    templateUrl: './status-display.component.html'
})
export class StatusDisplayComponent {

    @Input() status: StatusEnum | string | null = StatusEnum.New;
    @Input() displayStatusString: boolean = true;
    @Input() displayStatusOrb: boolean = true;


    get statusAsTranslationKey(): string {
        let mainTranslationKey = "Status.";
        return mainTranslationKey + this.status?.toString();

    }

    get statusAsBadgeStatus(): BadgeStatusEnum {
        switch (this.status) {
            // statussen voor INFO
            case StatusEnum.New:
            case StatusEnum.Active:
            case StatusEnum.Task:
            case StatusEnum.Processing: // these did not exists in the previous badge states
            case StatusEnum.Concept: // these did not exists in the previous badge states
            // case Created //// this did exists in the previous badge states HOWEVER it seems to not exist as Status
                return BadgeStatusEnum.Info;

            // statussen voor SUCCESS
            case StatusEnum.Completed:
                return BadgeStatusEnum.Success;

            // statussen voor WARNING
            case StatusEnum.PendingSignature:
            case StatusEnum.PendingDownload:
            case StatusEnum.PendingApproval:
            case StatusEnum.Sent:
            // case Send //// this did exists in the previous badge states HOWEVER it seems to not exist as Status
            // case processbydigipoort //// this did exists in the previous badge states HOWEVER it seems to not exist as Status
            case StatusEnum.Filing:
            case StatusEnum.PendingDigipoort:
            case StatusEnum.PendingSbrNexus:
            case StatusEnum.Deleted: 
            case StatusEnum.Purged:
            // case Signed //// this did exists in the previous badge states HOWEVER it seems to not exist as Status
            // case Delivering //// this did exists in the previous badge states HOWEVER it seems to not exist as Status
                return BadgeStatusEnum.Warning;

            // statussen voor DANGER
            case StatusEnum.Error:
            case StatusEnum.Expired:
            case StatusEnum.Withdrawn:
            case StatusEnum.Declined:
            case StatusEnum.InvitationExpired: 
            case StatusEnum.Failed: // these did not exists in the previous badge states
            case StatusEnum.PendingWithIssues: // 'Pending' but with error so mark it like DANGEEERRRZONEEE
                return BadgeStatusEnum.Danger;

            // Null, invalid or strange input word WARNING
            case null:
            case undefined:
            case "":
            default:
                return BadgeStatusEnum.Warning;
        }

    }
}
