<div class="rounded-corners">
<table mat-table [dataSource]="tableData" cdkDropList cdkDropListOrientation="horizontal" multiTemplateDataRows>
  <!-- Loop columnsToDisplay array -->
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.columnName">
    <!-- HEADER -->
    <ng-container *ngIf="column.columnName !== 'actions'">
      <th mat-header-cell cdkDrag *matHeaderCellDef translate="Table.{{column.transTag}}"
        class="col-{{column.noOfColumns}}"></th>
    </ng-container>
    <ng-container *ngIf="column.columnName === 'actions'">
      <th mat-header-cell cdkDrag *matHeaderCellDef class="col-1"></th>
    </ng-container>

    <!-- BODY -->
    <ng-container *ngIf="column.columnName !== 'actions'">
      <td mat-cell *matCellDef="let document" class="col-{{column.noOfColumns}}">
        {{ document[column.columnName] | valueByTypePipe : column.type }}
        <!-- Add copy to clipboard function, based on datatype Guid -->
        <ng-container *ngIf="column.type === 'Guid'">
          <em class="bi bi-copy cursor-pointer copy-icon" aria-hidden="true" (click)="copyToClipboard(document[column.columnName])"></em>
        </ng-container>
      </td>
    </ng-container>
    <ng-container *ngIf="column.columnName === 'actions'">
      <td mat-cell *matCellDef="let document" class="col-{{column.noOfColumns}}">
        <span class="float-end"
          *ngIf="openPreviewAllowed() && isPossibleToViewRendering(document)">
          <button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots bi-large"></i>
          </button>
          <ul class="dropdown-menu">
            <li *ngIf="openPreviewAllowed()">
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('openPreview', null, document.identifier)"
                translate="Button.ShowDocument"></a></li>
          </ul>
        </span>
      </td>
    </ng-container>
  </ng-container>

  <ng-container matColumnDef="message">
    <td *matCellDef="let documentWithError" mat-cell [attr.colspan]="displayedColumns.length">
      <div class="break-words">
        <div class="alert alert-danger p-1 mt-0 white-space" role="alert">
          {{documentWithError.logErrorMessage}}
        </div>
      </div>
    </td>
  </ng-container>
  <!-- Display data and declare specific embedded component -->
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['message']; when: hasErrorMessage"></tr>
</table>
</div>